/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import Helmet from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 function SEO({ description, lang, url, meta, title, image, ahrefs, google}) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
   )
 
   const metaDescription = description || site.siteMetadata.description
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={title}
       titleTemplate={`%s`}
       meta={[
         {
           name: `description`,
           content: description,
         },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: description,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           property: 'og:url',
           content: url
         },
         {
           property: `fb:app_id`,
           content: '176656027020247'
         },
         {
           property: `twitter:card`,
           content: `summary`,
         },
         {
           property: `og:image`,
           content: image
         },
         {
           property: `twitter:title`,
           content: title,
         },
         {
           property: `twitter:image`,
           content: image
         },
         {
           name: `ahrefs-site-verification`,
           content: ahrefs
         },
         {
           name: `google-site-verification`,
           content: google
         },
         {
           property: `twitter:description`,
           content: description
         },
       ].concat(meta)}
     >
     <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"></link>
     </Helmet>
   )
 }
 
 SEO.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   image: PropTypes.string
 }
 
 export default SEO